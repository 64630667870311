'use client'

import { createContext, useContext, useEffect, useState } from "react"

const ProductPurchaseContext = createContext<any>(undefined)

type Product = { id: string, total: number, quantity: number, minPurchase: number, maxPurchase: number, purchaseUnit: number, referenceUnit: number, name: string, calculatedPrices: any, prices: { unitPrice: number, quantity: number }[] }

export function useProductPurchaseContext() {
    return useContext(ProductPurchaseContext)
}

const ProductPurchaseProvider = ({ children, product: propProduct }: { children: React.ReactNode, product: Product }) => {
    let [product, setProduct] = useState<Product>()
    let [amount, setAmount] = useState<number>(0)
    let [calculatedPrice, setCalculatedPrice] = useState<any | undefined>()
    let [nextCalculatedPrice, setNextCalculatedPrice] = useState<any | undefined>()
    

    useEffect(() => setProduct(propProduct), [propProduct])

    useEffect(() => {
        if (undefined === product) return
        setAmount(product.minPurchase ?? 1)
    }, [product])

    useEffect(() => {
        if (undefined === product?.calculatedPrices) return

        let nextCalculatedPriceIndex = product.calculatedPrices.length;
        for (let i = product.calculatedPrices.length - 1; 0 <= i; i--) {
            if (product.calculatedPrices[i].quantity >= amount) {
                nextCalculatedPriceIndex = i;
            }
        }

        setCalculatedPrice(product.calculatedPrices[Math.min(nextCalculatedPriceIndex, product.calculatedPrices.length - 1)])
        setNextCalculatedPrice(product.calculatedPrices[Math.min(nextCalculatedPriceIndex + 1, product.calculatedPrices.length - 1)])
    }, [amount])

    const checkAmount = (input: number) => input

    return (
        <ProductPurchaseContext.Provider value={{
            product,
            setProduct,

            amount,
            setAmount,

            checkAmount,

            calculatedPrice,
            nextCalculatedPrice
        }}>
            {children}
        </ProductPurchaseContext.Provider>
    )
}

export default ProductPurchaseProvider
